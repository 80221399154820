<template>
  <div>
    <div class="topWrap">
      <div class="content">
        <i class="icon iconfont close" @click="closeWindow()">&#xe680;</i>
        <div class="contentLeft"></div>
        <!-- 登录 -->
        <div class="contentRight" v-show="type == '1'">
          <h3>用户登录</h3>
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item prop="mobileOrEmail">
              <el-input v-model="form.mobileOrEmail" placeholder="请输入手机号码">
                <template #prefix>
                  <i slot="prefix" class="iconfont"> &#xe67e; </i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="loginPwd">
              <el-input
                v-model="form.loginPwd"
                type="password"
                placeholder="请输入登录密码"
              >
                <template #prefix>
                  <i slot="prefix" class="iconfont"> &#xe67f; </i>
                </template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item prop="verifyCode">
                    <el-input v-model="form.verifyCode" placeholder="请输入短信验证码">
                      <i  slot="prefix" class="iconfont"> &#xe609; </i>
                      <em slot="suffix" class="yzm"> 发送验证码 </em>
                    </el-input>
                  </el-form-item> -->
            <!-- <el-form-item prop="allow">
                      <el-checkbox v-model="form.allow">记住密码</el-checkbox>
                  </el-form-item> -->
            <el-form-item prop="agreement" class="allow">
                <el-checkbox v-model="agreement">已阅读并同意</el-checkbox>
                <router-link :to="{path:'/Agreement',query: {}}" target="_blank">
                    《用户使用协议》《隐私政策》
                </router-link>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">登录</el-button>
            </el-form-item>
          </el-form>

          <div class="goLogin goLogin1">
            <!-- <router-link :to="{ path: '/register', query: {} }"> -->
              <!-- 立即注册 -->
            <!-- </router-link> -->
            <!-- <router-link :to="{ path: '/forgetPwd', query: {} }">
              忘记密码
            </router-link> -->
            <div>还没有账号？点击<span @click="changeType(2)">立即注册</span></div>
            <span @click="changeType(3)">忘记密码</span>
          </div>
        </div>
        <!-- 注册 -->
        <div class="contentRight" v-show="type == '2'">
          <h3>用户注册</h3>
              <el-form :model="form2" :rules="rules2" ref="form2">
                <!-- <el-form-item prop="name">
                  <el-input v-model="form.nickname" placeholder="昵称">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe669; </i>
                    </template>
                  </el-input>
                </el-form-item> -->
                <el-form-item prop="mobileOrEmail">
                  <el-input v-model="form2.mobileOrEmail" placeholder="请输入手机号码">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe67e; </i>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="verifyCode">
                  <el-input v-model="form2.verifyCode" placeholder="请输入短信验证码">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe67d; </i>
                    </template>
                    <template #suffix>
                      <em slot="suffix" class="yzm" @click="sendVerifyCode"> {{sendBtnText}}  </em>
                    </template>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="loginPwd">
                  <el-input v-model="form2.loginPwd" type="password" placeholder="设置6至20位登录密码">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe67f; </i>
                    </template>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="reLoginPwd">
                  <el-input v-model="form2.reLoginPwd" type="password" placeholder="请再次输入登录密码">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe67f; </i>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="agreement" class="allow">
                    <el-checkbox v-model="agreement">已阅读并同意</el-checkbox>
                      <router-link :to="{path:'/Agreement',query: {}}" target="_blank"> 
                          《用户使用协议》《隐私政策》
                      </router-link>
                </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit2">注册</el-button>
                  </el-form-item>
              </el-form>  
              
                  <div class="goLogin">
                    <!-- <router-link :to="{path:'/Login',query: {}}">  -->
                          <!-- 立即登录 -->
                      <!-- </router-link> -->
                    <div>已有账号，点击<span @click="changeType(1)">立即登录</span></div>
                  </div> 
        </div>
        <!-- 忘记密码 -->
        <div class="contentRight" v-show="type == '3'">
          <h3>忘记密码</h3>
              <el-form :model="form3" :rules="rules3" ref="form3">
                <!-- <el-form-item prop="name">
                  <el-input v-model="form.nickname" placeholder="昵称">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe669; </i>
                    </template>
                  </el-input>
                </el-form-item> -->
                <el-form-item prop="mobileOrEmail">
                  <el-input v-model="form3.mobileOrEmail" placeholder="请输入手机号码">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe67e; </i>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="verifyCode">
                  <el-input v-model="form3.verifyCode" placeholder="请输入短信验证码">
                    <template #prefix>
                      <i  slot="prefix" class="iconfont"> &#xe67d; </i>
                    </template>
                    <template #suffix>
                      <em slot="suffix" class="yzm" @click="sendVerifyCode2"> {{sendBtnText}}  </em>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="newPassword">
                  <el-input v-model="form3.newPassword" type="password" placeholder="请设置新的登录密码(6至20位)">
                     <i  slot="prefix" class="iconfont"> &#xe65d; </i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="agreement" class="allow">
                    <el-checkbox v-model="agreement">已阅读并同意</el-checkbox>
                      <router-link :to="{path:'/Agreement',query: {}}" target="_blank"> 
                          《用户使用协议》《隐私政策》
                      </router-link>
                </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit3">修改密码</el-button>
                  </el-form-item>
              </el-form>  
              
                  <div class="goLogin">
                    <!-- <router-link :to="{path:'/Login',query: {}}">  -->
                          <!-- 立即登录 -->
                      <!-- </router-link> -->
                    <div>修改成功，点击<span @click="changeType(1)">立即登录</span></div>
                  </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login,register,forgetPassword } from "@/api/UserService";
// import bus from '@/bus'
import {sendSmsVerifyCode} from '@/api/SmsService'
export default {
  name: "Login",
  components: {
  },
  mounted() {
    this.init();
  },
  props: {
    dialogType: String,
    default: '1'
  },
  data() {
    return {
      type: this.dialogType,
      redirect:this.$route.query.redirect,
      form: { // 登录
        mobileOrEmail: "",
        verifyCode: "",
        loginPwd: "",
      },
      form2:{ // 注册
        nickname:'',
        mobileOrEmail:'',
        verifyCode:'',
        loginPwd:'',
        reLoginPwd:'',
        verifyCodeId:null,
        allow:false
      },
      form3:{ // 忘记密码
        mobileOrEmail:'',
        verifyCode:'',
        newPassword:'',
        verifyCodeId:null,
      },
      sendBtnText:'发送验证码',
      sendStatus:-1,
      agreement: true,
      rules: {
        mobileOrEmail:[
          { required: true, validator: this.valiPhoneNum, trigger: "blur" },
        ],
        loginPwd: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        agreement: [
          { required: true, validator: this.valiAgreement, trigger: "change" },
        ],
      },
      rules2: {
        mobileOrEmail:[
          { required: true, validator: this.valiPhoneNum, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ],
        loginPwd: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        reLoginPwd: [
          { required: true, message: '请再次输入登录密码', trigger: 'blur' }
        ],
        agreement: [
          { required: true, validator: this.valiAgreement, trigger: "change" },
        ],
      },
      rules3: {
        mobileOrEmail:[
          { required: true, validator: this.valiPhoneNum, trigger: "blur" },
        ],
        verifyCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请设置新的登录密码', trigger: 'blur' }
        ],
        agreement: [
          { required: true, validator: this.valiAgreement, trigger: "change" },
        ],
      }
    };
  },
  methods: {
    init() {},
    sendVerifyCode(){
      if(this.sendStatus!=-1){
        return false;
      }

      this.sendStatus=0;
      this.sendBtnText='发送中';

      sendSmsVerifyCode(`+86-${this.form2.mobileOrEmail}`,'REGISTER').then(({data,res})=>{
          this.form2.verifyCodeId=data;
          this.sendStatus=1;

          let that=this;
          let count=90;
          let refreshVerifyCode=()=>{
              if(count==0){
                 that.sendBtnText='重新发送';
                 that.sendStatus=-1;
                 return false;
              }else{
                 count--;
                 that.sendBtnText=`${count}秒后重发`;
                 setTimeout(refreshVerifyCode,1000);
              }
          }
          setTimeout(refreshVerifyCode,1000);
      }).catch(e=>{
        this.sendBtnText='发送验证码';
        this.sendStatus=-1;
        console.log(e);
      });
    },
    sendVerifyCode2(){
      if(this.sendStatus!=-1){
        return false;
      }

      this.sendStatus=0;
      this.sendBtnText='发送中';

      sendSmsVerifyCode(`+86-${this.form3.mobileOrEmail}`,'forget-pwd').then(({data,res})=>{
          this.form3.verifyCodeId=data;
          this.sendStatus=1;

          let that=this;
          let count=90;
          let refreshVerifyCode=()=>{
              if(count==0){
                 that.sendBtnText='重新发送';
                 that.sendStatus=-1;
                 return false;
              }else{
                 count--;
                 that.sendBtnText=`${count}秒后重发`;
                 setTimeout(refreshVerifyCode,1000);
              }
          }
          setTimeout(refreshVerifyCode,1000);
      }).catch(e=>{
        this.sendBtnText='发送验证码';
        this.sendStatus=-1;
        console.log(e);
      });
    },

    // 手机号码校验函数
    valiPhoneNum(rule, value, callback) {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号码'));
      }
    },
    // 自定义验证同意协议
    valiAgreement(rule, value, callback){
      if (!this.agreement) {
        callback(new Error("请勾选同意协议"));
        return;
      }
      callback();
    },

    async onSubmit() {
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        // this.mixinsScrollView();
        return;
      }
      login(this.form).then(({ data, res }) => {
        // console.log(data, res);
        this.$store.commit("login", {
          userInfo: data,
          token: res.headers.get("Authorization"),
        });
        this.bus.$emit("refreshInfo");
        this.bus.$emit("refreshVip");
        this.bus.$emit("refreshObligee");

        this.$emit('login'); // 发出登录事件

        // 添加本地缓存
        localStorage.setItem('userInfo', JSON.stringify(data));
        
        if(this.redirect){
          this.$router.push(this.redirect);
        }else{
          // console.log(data);
          // if(data.realName==''){
          //   this.$router.push("/perfect");
          // }else{
          //   this.$router.push("/");
          // }
        }
        this.$emit('close');
      });
    },

    async onSubmit2(){
      try {
        const formBaseResult = await this.$refs["form2"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        // this.mixinsScrollView();
        return;
      }
      register(this.form2).then((data)=>{
        //  this.$router.push({path: '/Login'});
         this.$message.success('注册成功!');
        setTimeout(()=>{
          this.type = '1';
        },1000)
      }).catch(e=>{
          console.log(e);
      });
    },

    async onSubmit3(){
      try {
        const formBaseResult = await this.$refs["form3"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        // this.mixinsScrollView();
        return;
      }

      const params={
          ...this.form3,
          mobileOrEmail:`+86-${this.form3.mobileOrEmail}`
      }
      forgetPassword(params).then((data)=>{
        this.$message.success('修改密码成功!');
        setTimeout(()=>{
          this.type = '1';
        },1000)
      }).catch(e=>{
          console.log(e);
      });
    },

    closeWindow(){
      this.$emit('close');
    },
    
    changeType(type){
      this.type = type;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/loginRigist.scss";
.goLogin{display: flex!important;text-align: center;justify-content: center!important;width: 560px;padding: 0 100px;}
.goLogin1{justify-content: space-between!important;}
.topWrap{position: fixed;left: 0;top: 0;background: rgba(0,0,0,0.5);z-index: 999;width: 100%;height: 100%;}
.content{position: relative;}
.close{position: absolute;right: 20px;top: 20px;font-size: 22px!important;opacity: 0.8!important;cursor: pointer;z-index: 1;}
.contentLeft{
  background: url('../assets/images/loginBg02.png')no-repeat center;
}

// 适配移动端
@media screen and (max-width: 991px){
  .contentLeft{display: none!important;}
  .contentRight{padding: 30px 30px!important;}
  .goLogin{padding: 0 30px!important;}
}
</style>