<template>
  <div id="app" @click="isTimeOut">
    <head-block :activeIdx="activeManue" v-if="!$route.meta.noShowHeader"></head-block>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件 -->
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件 -->
    </router-view>

    <foot-block v-if="!$route.meta.noShowHeader"></foot-block>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeadBlock from "@/components/HeadBlock";
import FootBlock from "@/components/FootBlock";
import { queryUserInfo } from "@/api/UserService";
import { queryPaymentPrice,queryVipConfig,queryVipInfo } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
import {logout} from '@/api/UserService'
export default {
  name: "App",
  components: {
    FootBlock,
    HeadBlock,
  },
  data() {
    return {
      lastTime: null, // 最后一次点击的时间
      currentTime: null, // 当前点击的时间
      timeOut: 15 * 60 * 1000 // 设置超时时间:15分钟
      // timeOut: 15 * 60 * 10 // 设置超时时间:15分钟
    };
  },
  updated() {
    window.scroll(0, 0); //切换页面之后，页面始终在上面
  },
  computed: {
    ...mapGetters(["activeManue", "mid", "userInfo", "evidencePaymentPrice", "vipInfo","authInfo"]),
  },
  mounted() {
    // this.init();
    this.bus.$on("refreshVip", this.refreshVip); 
    this.bus.$on("refreshObligee", this.refreshObligee); 
    this.bus.$on("refreshInfo", this.refreshInfo); 
  },
  watch: {
    $route(to, from) {
      console.log(to.name);
      this.$store.commit("setActiveManue", to.name);
    },
  },
  created () {
    this.lastTime = new Date().getTime()
    this.init();
  },
  methods: {
    init() {
      this.$store.commit("setActiveManue", this.$route.name);
      // console.log("check user info");
      this.loadCommonData();
      if (this.mid && !this.userInfo) {
       this.refreshInfo();
        this.refreshVip();
        this.refreshObligee();
      }
    },
    refreshInfo(){
        if (this.mid) {
        queryUserInfo(this.mid).then(({ data, res }) => {
            this.$store.commit("setUserInfo", data);
          });
        }
    },
    refreshObligee(){
   if (this.mid) {
         //查询是否实名认证
        queryObligeeList(1, 1).then(({ data }) => {
          if (data && data.list.length > 0) {
            this.$store.commit("setAuthInfo", data.list[0]);
          }
        });
   }
    },
    refreshVip(){
          if (this.mid) {
          queryVipInfo().then(({ data }) => {
                  this.$store.commit("setVipInfo", data);
                });
          }
    },
    loadCommonData(){
        queryVipConfig().then(({ data }) => {
          this.$store.commit("setVipConfig", data);
        });

           queryPaymentPrice().then(({ data }) => {
            this.$store.commit("setEvidencePaymentPrice", data);
          });
    },


    // 登录超时设置
    isTimeOut () {
      // console.log('登录超时设置');
      this.currentTime = new Date().getTime() // 记录这次点击的时间
      // console.log('currentTime当前时间',this.currentTime);
      // console.log('lastTime最后点击时间',this.lastTime);
      // console.log('currentTime - lastTime当前时间-上次最后点击时间',this.currentTime - this.lastTime);
      // console.log('timeOut固定间隔',this.timeOut);
      if (this.currentTime - this.lastTime > this.timeOut) { // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于30分钟
        if (this.userInfo) { // 如果是登录状态
          // localStorage.clear()
          sessionStorage.clear();
          let that=this;
          logout().then(({data,res})=>{
              that.$store.commit('logout');
              window.location.replace(window.location.protocol + "//"+window.location.host)
              localStorage.removeItem('userInfo');
          });
          // this.$router.push({name: 'Login'})
        } else {
          this.lastTime = new Date().getTime()
        }
      } else {
        this.lastTime = new Date().getTime() // 如果在30分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
      }
    }
  },


};
</script>
<style lang="scss">
</style>
